import { composite, css, easing, stagger, tween } from "../libraries/popmotion/lib/popmotion"
import SmoothScroll from "smooth-scroll/dist/js/smooth-scroll"

const portfolio = () => {
  const scroll = new SmoothScroll()
  const anchor = document.querySelector("#portfolio")

  const blocks = Array.from(document.querySelectorAll(".containerPortfolio"))
  const blocksSplice = blocks.splice(3, blocks.length)
  const button = document.querySelector(".buttonMoreItemPortfolio")

  button.addEventListener("click", () => {
    if (button.classList.contains("active")) {
      stagger(animateItem(blocksSplice, 999, 0, true), 50).start()
      button.classList.remove("active")
      scroll.animateScroll(anchor, false, {
        speed: 800,
        offset: 54
      })
      button.innerHTML = "Показать еще"
    } else {
      stagger(animateItem(blocksSplice, 0, 999, false), 1).start()
      button.classList.add("active")
      button.innerHTML = "Свернуть"
    }
  })
}

export default portfolio

const animateItem = (elements, aFrom, aTo, reverse = false) => {
  const arr = reverse ? elements.reverse() : elements
  return arr.map(element => {
    const elementRenderer = css(element)
    return composite({
      x: tween({
        from: aFrom,
        to: aTo,
        duration: reverse ? 1 : 200,
        ease: easing.easeInOut,
        onUpdate: v => {
          elementRenderer.set("maxHeight", v)
        }
      }),
      y: tween({
        from: reverse ? 1 : 0,
        to: reverse ? 0 : 1,
        duration: reverse ? 1 : 200,
        ease: easing.easeInOut,
        onUpdate: v => {
          elementRenderer.set("opacity", v)
        }
      })
    })
  })
}
