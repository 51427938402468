import { q, qq } from "../helper/q"
import tingle from "tingle.js"

export const vacancyInit = () => {
  let modal = new tingle.modal({
    footer: false,
    stickyFooter: false,
    closeMethods: ["overlay", "escape"],
    closeLabel: "Close",
    onOpen: () => {
      q(".vacancyPopupClose").addEventListener("click", () => {
        modal.close()
      })
    },
    beforeClose: function() {
      q(".vacancyPopupClose").removeEventListener("click", () => {
        modal.close()
      })
      return true
    }
  })

  const items = qq(".vacancyBodyItem")

  items.map(item => {
    q(".vacancyBodyButton", item).addEventListener("click", e => {
      const itemContent = q(".vacancyContent", item)
      modal.setContent(itemContent.innerHTML)
      modal.open()
    })
  })
}
