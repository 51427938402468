export const inputInit = () => {
  const inputs = Array.from(document.querySelectorAll(".calculationInput"))

  inputs.map(input => {
    const wrapper = wrapInput(input)
    const placeholder = input.placeholder
    addLabel(wrapper, input)
    if (input.value) wrapper.classList.add("inputFocus")

    input.addEventListener("focus", () => {
      wrapper.classList.add("inputFocus")
    })

    input.addEventListener("blur", () => {
      if (!input.value) {
        wrapper.classList.remove("inputFocus")
        wrapper.classList.remove("inputError")
        wrapper.querySelector(".inputLabel").innerHTML = placeholder
      }

      if (input.dataset.required) {
        if (input.value === "") {
          wrapper.classList.add("inputError")
          if (input.dataset.error !== "") {
            if (window.matchMedia("(min-width: 768px)").matches) {
              wrapper.querySelector(".inputLabel").innerHTML = input.dataset.error
            }
          }
        }
      }
    })

    input.addEventListener("input", e => {
      if (input.dataset.required) {
        input.value = e.target.value
        if (input.value === "") {
          if (input.dataset.error !== "") {
            if (window.matchMedia("(min-width: 768px)").matches) {
              wrapper.querySelector(".inputLabel").innerHTML = input.dataset.error
            }
          }
        } else {
          wrapper.querySelector(".inputLabel").innerHTML = placeholder
          wrapper.classList.remove("inputError")
        }
      }
    })
  })
}

const wrapInput = input => {
  const wrapper = document.createElement("div")
  wrapper.classList.add("inputWrapper")
  if (input.dataset.required) wrapper.classList.add("inputRequired")
  input.parentNode.insertBefore(wrapper, input)
  wrapper.appendChild(input)
  return wrapper
}

const addLabel = (wrapper, input) => {
  const label = document.createElement("div")
  label.classList.add("inputLabel")
  label.innerHTML = input.placeholder
  input.placeholder = ""
  wrapper.appendChild(label)
}
