const options = {
  rootMargin: '50px 20px 75px 30px',
  threshold: [0, 0.25, 0.5, 0.75, 1]
}

const header = () => {
  window.addEventListener(
    'load',
    () => {
      const elements = Array.from(document.querySelectorAll('[data-title]'))
      elements.map(element => createObserver(element))
    },
    false
  )
}
let observer = new IntersectionObserver(handleIntersect, options)

function createObserver(element) {
  observer.observe(element)
}

function handleIntersect(entries) {
  const headerTitle = document.querySelector('.headerTitle')

  entries.forEach(function(entry) {
    if (entry.intersectionRatio > 0.5) {
      if (headerTitle.innerHTML !== entry.target.dataset.title) {
        headerTitle.innerHTML = entry.target.dataset.title
        headerTitle.classList.add('vhs-right')
        setTimeout(function() {
          headerTitle.classList.remove('vhs-right')
        }, 500)
      }
    }
  })
}

export default header
