import { composite, css, easing, stagger, tween } from "../libraries/popmotion/lib/popmotion"

const phrases = [
  "детали на заказ",
  "вал",
  "фланец",
  "пресс-форму",
  "матрицу",
  "пуансон",
  "метизы",
  "крепеж",
  "фурнитуру",
  "корпус",
  "рольганг"
]

const loadAnimates = () => {
  const logo = document.querySelector(".firstScreenLogo")
  const title = document.querySelector(".firstScreenContentText")
  const link = document.querySelector(".firstScreenContentLink")
  const menu = document.querySelector(".menuButton")
  const scroll = document.querySelector(".firstScreenScroll")

  stagger(
    [
      animateItem(logo, 50, 0, "y", 800),
      animateItem(title, 50, 0, "y", 800),
      animateItem(link, 50, 0, "y", 800),
      animateItem(menu, 50, 0, "x", 800),
      animateItem(scroll, 0, 1, "scale", 500)
    ],
    200,
    () => {
      animatePhrase()
    }
  ).start()

  // animatePhrase()
}

export default loadAnimates

const animatePhrase = () => {
  const div = document.querySelector(".firstScreenContentLink")
  let index = 1
  setInterval(() => {
    div.innerHTML = phrases[index]
    animateItem(div, 50, 0, "y", 500).start()
    index = index >= phrases.length - 1 ? 0 : index + 1
  }, 4700)
}

const animateItem = (element, aFrom, aTo, aDirect = "x", aDuration, reverse = false) => {
  const elementRenderer = css(element)
  return composite({
    x: tween({
      from: aFrom,
      to: aTo,
      duration: aDuration,
      ease: easing.easeOut,
      onUpdate: v => {
        elementRenderer.set(aDirect, v)
      }
    }),
    y: tween({
      from: reverse ? 1 : 0,
      to: reverse ? 0 : 1,
      duration: aDuration,
      ease: easing.easeOut,
      onUpdate: v => {
        elementRenderer.set("opacity", v)
      }
    })
  })
}
