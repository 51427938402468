const mapInit = () => {
  const stylesMap = [
    {
      "featureType": "administrative",
      "elementType": "all",
      "stylers": [
        {
          "saturation": "-100"
        }
      ]
    },
    {
      "featureType": "administrative.province",
      "elementType": "all",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "landscape",
      "elementType": "all",
      "stylers": [
        {
          "saturation": -100
        },
        {
          "lightness": 65
        },
        {
          "visibility": "on"
        }
      ]
    },
    {
      "featureType": "poi",
      "elementType": "all",
      "stylers": [
        {
          "saturation": -100
        },
        {
          "lightness": "50"
        },
        {
          "visibility": "simplified"
        }
      ]
    },
    {
      "featureType": "road",
      "elementType": "all",
      "stylers": [
        {
          "saturation": "-100"
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "all",
      "stylers": [
        {
          "visibility": "simplified"
        }
      ]
    },
    {
      "featureType": "road.arterial",
      "elementType": "all",
      "stylers": [
        {
          "lightness": "30"
        }
      ]
    },
    {
      "featureType": "road.local",
      "elementType": "all",
      "stylers": [
        {
          "lightness": "40"
        }
      ]
    },
    {
      "featureType": "transit",
      "elementType": "all",
      "stylers": [
        {
          "saturation": -100
        },
        {
          "visibility": "simplified"
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "geometry",
      "stylers": [
        {
          "hue": "#ffff00"
        },
        {
          "lightness": -25
        },
        {
          "saturation": -97
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "labels",
      "stylers": [
        {
          "lightness": -25
        },
        {
          "saturation": -100
        }
      ]
    }
  ]

  const mapStores = new google.maps.Map(document.getElementById('map'), {
    scrollwheel: false,
    zoom: 16,
    // center: new google.maps.LatLng(59.895638, 30.39456),
    center: centerMap(),
    streetViewControl: false,
    mapTypeControl: false,
    styles: stylesMap,
    scaleControl: false,
    zoomControl: false,
    fullscreenControl: false
  })

  const marker = new google.maps.Marker({
    icon: setMarker(),
    position: new google.maps.LatLng(59.756007, 30.597499),
    map: mapStores,
    optimized: false
  })
}

const setMarker = () => {
  return new google.maps.MarkerImage(
    'img/icon-marker.svg',
    new google.maps.Size(24, 24),
    new google.maps.Point(0, 0),
    new google.maps.Point(24, 24),
    new google.maps.Size(24, 24)
  )
}

export default mapInit

const centerMap = () => {
  if (window.matchMedia('(max-width: 1000px)').matches) {
    return new google.maps.LatLng(59.756007, 30.597499)
  } else {
    return new google.maps.LatLng(59.756007, 30.604499)
  }
}
