import Swiper from '../libraries/swiper.min.js'

// слайдеры
export default function sliders() {
  const sliderEquipment = Array.from(document.querySelectorAll('.sliderEquipment'))
  let sliderEquipmentDesktop = []
  sliderEquipment.map((slider, index) => {
    sliderEquipmentDesktop[index] = new Swiper(sliderEquipment[index], {
      slidesPerView: 1,
      speed: 400,
      spaceBetween: 14,
      autoplay: 2500,
      loop: true,
      navigation: {
        prevEl: sliderEquipment[index].parentNode.querySelector('.sliderEquipmentButtonPrev'),
        nextEl: sliderEquipment[index].parentNode.querySelector('.sliderEquipmentButtonNext')
      },
      pagination: {
        el: sliderEquipment[index].parentNode.querySelector('.sliderEquipmentPagination'),
        type: 'fraction'
      }
    })
  })

  const sliderCompetence = new Swiper('.sliderCompetence', {
    slidesPerView: 1,
    speed: 400,
    spaceBetween: 14,
    autoplay: 2500,
    loop: true,
    roundLengths: true,
    pagination: {
      el: '.sliderCompetencePagination',
      clickable: true
    }
  })
}
