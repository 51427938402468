import hyperform from "hyperform"

const calculation = () => {
  const form = hyperform(document.querySelector(".calculationForm"))
  let $form = $(".calculationForm")
  let $inputRequired = $form.find(".calculationInputWrapperRequired").children(".calculationInput")
  let $calculationButton = $form.children(".calculationButton")

  $form.submit(function(e) {
    let $this = $(this).closest(".calculationForm")
    let $containerCalculation = $(this).closest(".containerCalculation")
    let url = $(this).attr("action")
    let formData = new FormData(this) // вместо serialize() чтоб файл захватился
    // $.post( url, formData, function(data) {
    //     if (data.STATUS == 'ok') {
    //         console.log(new FormData(this));
    //         $containerCalculation.find('.calculationForm').addClass('calculationFormHidden');
    //         $containerCalculation.find('.calculationSent').removeClass('calculationSentHidden');
    //     } else if (data.STATUS == 'error') {
    //         $inputRequired.addClass('calculationInputError');
    //     }
    // }, 'json').fail(function() {
    //     $inputRequired.addClass('calculationInputError');
    // });
    $.ajax({
      // $.post не подходит, нет processData и contentType
      url: url,
      type: "POST",
      dataType: "JSON",
      data: formData,
      processData: false,
      contentType: false,
      success: function(data, status) {
        if (data.STATUS == "ok") {
          // console.log(new FormData(this));
          $containerCalculation.find(".calculationForm").addClass("calculationFormHidden")
          $containerCalculation.find(".calculationSent").removeClass("calculationSentHidden")
        } else if (data.STATUS == "error") {
          $inputRequired.addClass("calculationInputError")
        }
      },
      error: function(xhr, desc, err) {
        console.log(err)
        // $inputRequired.addClass("calculationInputError")
      }
    })
    e.preventDefault()
  })

  $inputRequired.change(function() {
    let $inputs = $(this)
    $inputRequired.each(function() {
      if ($(this).val() == "") {
        $calculationButton.addClass("calculationButtonDisable")
        $calculationButton.prop("disabled", true)
        return false
      } else {
        $calculationButton.removeClass("calculationButtonDisable")
        $calculationButton.prop("disabled", false)
      }
    })
  })

  let calculationButtonAdd = document.querySelector(".calculationButtonAdd")
  let calculationFiles = document.querySelector(".calculationFiles")
  let calculationButtonAddFiles = document.querySelector(".calculationButtonAdd").files
  let newFile = document.createElement("div")

  calculationButtonAdd.addEventListener("change", () => {
    calculationButtonAddFiles = document.querySelector(".calculationButtonAdd").files
    if (calculationButtonAddFiles.length < 5) {
      for (let i = 0; i < calculationButtonAddFiles.length; i++) {
        newFile.innerHTML =
          newFile.innerHTML +
          "<div class='calculationFile'>" +
          "<span class='calculationFileNumber'>" +
          (i + 1) +
          "</span>" +
          "<span class='calculationFileName'>" +
          calculationButtonAddFiles[i].name +
          "</span>" +
          "<span class='calculationFileSize'>" +
          calculationButtonAddFiles[i].size / 125 +
          " Кб" +
          "</span>" +
          "</div>"
        calculationFiles.appendChild(newFile)
      }
    } else if (calculationButtonAddFiles.length >= 5) {
      return false
    }
  })
}

export default calculation
