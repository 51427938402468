import { composite, css, easing, stagger, tween } from "../libraries/popmotion/lib/popmotion"

import SmoothScroll from "smooth-scroll/dist/js/smooth-scroll"

const menu = () => {
  const button = document.querySelector(".menuForm")
  const buttonTop = document.querySelector(".menuButton")
  const openMenu = [button, buttonTop]

  const body = document.querySelector("body")
  const close = document.querySelector(".menuClose")

  const links = Array.from(document.querySelectorAll('a[href*="#"]'))
  const scroll = new SmoothScroll()
  links.map(link => {
    if (link.hash) {
      link.addEventListener("click", e => {
        e.preventDefault()
        if (body.classList.contains("menuIsOpen")) {
          body.classList.remove("menuIsOpen")
          stagger(animateItem(menuElements, 0, -100, "x", true), 50, () => {
            stagger(animateItem(menuColumns, 0, 50, "y", true), 50, () => {
              stagger(footerTweens, 150).start()
            }).start()
          }).start()
          setTimeout(() => {
            scroll.animateScroll(document.querySelector(link.hash), false, {
              speed: 800,
              offset: 54
            })
          }, 500)
        } else {
          scroll.animateScroll(document.querySelector(link.hash), false, {
            speed: 800,
            offset: 54
          })
        }
      })
    }
  })

  const menuElements = Array.from(document.querySelectorAll(".menuMainElement"))
  const menuColumns = Array.from(document.querySelectorAll(".menuColumnElement"))
  const footerElements = Array.from(document.querySelectorAll(".menuFooterItem svg"))

  const footerTweens = footerElements.map(element => {
    const elementRenderer = css(element)
    return tween({
      from: 1,
      to: 2,
      yoyo: 1,
      ease: easing.easeInOut,
      onUpdate: v => {
        elementRenderer.set("scale", v)
      }
    })
  })

  // OnLoad Reverse animation
  stagger(animateItem(menuColumns, 50, 0, "y", true), 10).start()
  stagger(animateItem(menuElements, 0, -100, "y", true), 10).start()

  openMenu.map(button => {
    button.addEventListener("click", () => {
      // if (body.classList.contains('menuIsOpen')) {
      //   body.classList.remove('menuIsOpen')
      // } else {
      body.classList.add("menuIsOpen")
      setTimeout(() => {
        stagger(animateItem(menuColumns, -100, 0, "x"), 50, () => {
          stagger(animateItem(menuElements, 50, 0, "y"), 50, () => {
            stagger(footerTweens, 150).start()
          }).start()
        }).start()
      }, 150)
      // }
    })
  })

  close.addEventListener("click", () => {
    body.classList.remove("menuIsOpen")
    stagger(animateItem(menuColumns, 0, -100, "x", true), 50, () => {
      stagger(animateItem(menuElements, 0, 50, "y", true), 50, () => {
        stagger(footerTweens, 150).start()
      }).start()
    }).start()
  })
}

export default menu

const animateItem = (elements, aFrom, aTo, aDirect = "x", reverse = false) => {
  return elements.map(element => {
    const elementRenderer = css(element)
    return composite({
      x: tween({
        from: aFrom,
        to: aTo,
        ease: easing.easeInOut,
        onUpdate: v => {
          elementRenderer.set(aDirect, v)
        }
      }),
      y: tween({
        from: reverse ? 1 : 0,
        to: reverse ? 0 : 1,
        ease: easing.linear,
        onUpdate: v => {
          elementRenderer.set("opacity", v)
        }
      })
    })
  })
}
