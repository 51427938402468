import calculation from "./component/calculation"
import equipment from "./component/equipment"
import header from "./component/header"
import { inputInit } from "./component/input"
import loadAnimates from "./component/load"
import mapInit from "./component/map"
import menu from "./component/menu"
import portfolio from "./component/portfolio"
import sliders from "./component/sliders"
import stickybits from "stickybits"
import { vacancyInit } from "./component/vacancy"

document.addEventListener("DOMContentLoaded", function() {
  console.log("version 20.11.2018 - 11:31")
  header()
  menu()
  mapInit()
  sliders()
  loadAnimates()
  stickybits(".header", { useStickyClasses: true })
  portfolio()
  equipment()
  calculation()
  inputInit()
  vacancyInit()
})
